<template>
  <Head>
    <title>Умный дом {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Мгновенно реагирует на ваши желания, знает вас в лицо, обеспечивает безопасность и делает жизнь комфортнее в каждой детали. Управляйте атмосферой, сложной техникой, интеллектуальными сервисами дома и услугами в одном приложении."
    />
    <meta property="og:title" :content="'Умный дом ' + $titleEnd" />
    <meta
      property="og:description"
      content="Мгновенно реагирует на ваши желания, знает вас в лицо, обеспечивает безопасность и делает жизнь комфортнее в каждой детали. Управляйте атмосферой, сложной техникой, интеллектуальными сервисами дома и услугами в одном приложении."
    />
  </Head>
  <main class="main my-0">
    <Hero
      :content="hero"
      :breadcrumbs="[
        { name: 'Home', text: 'Главная' },
        { name: 'Advantages', text: 'Преимущества' },
        { text: hero.title },
      ]"
    />
    <Basis class="agehlribbqkvwxvb" :content="basis" />
    <Image :content="image" />
    <Others />
    <HomeChoose />
  </main>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Basis from "@/components/adv/inner/Basis.vue";
import Image from "@/components/adv/inner/Image.vue";
import Others from "@/components/adv/inner/Others.vue";
import HomeChoose from "@/components/home/Choose.vue";
import { Head } from "@vueuse/head";
export default {
  name: "Smart",
  components: {
    Head,
    Hero,
    Basis,
    Image,
    Others,
    HomeChoose,
  },
  data() {
    return {
      hero: {
        title: "Умный дом",
        text: "<p>Мгновенно реагирует на&nbsp;ваши желания, знает вас в&nbsp;лицо, обеспечивает безопасность и&nbsp;делает жизнь комфортнее в&nbsp;каждой детали.</p>",
        background: require("@i/html/smart/smart-hero.jpg"),
        video: require("@i/html/smart/smart.mp4"),
        more: "Узнайте больше",
      },

      basis: {
        title: 'Настроен <span class="text-masked">на&nbsp;вас</span>',
        subtitle: "Два клика до&nbsp;всего",
        text: "<p>Управляйте атмосферой, сложной техникой, интеллектуальными сервисами дома и&nbsp;услугами в&nbsp;одном приложении.</p>",
        list: [
          {
            title: "По&nbsp;вашему сценарию",
            text: "<p>Теплее или прохладнее, светлее или&nbsp;мягче. Умные системы и&nbsp;устройства ждут ваших указаний, а&nbsp;системы безопасности дарят спокойствие.</p>",
            img: require("@i/html/smart/smart-m-1.jpg"),
          },
          {
            title: "VERY персонифицирован",
            text: "<p>Достаточно вашего взгляда и&nbsp;дверь дома открывается. Для управления большинством сервисов и&nbsp;связи со&nbsp;службами дома хватит одного слова.</p>",
            img: require("@i/html/smart/smart-m-2.jpg"),
          },
          {
            title: "Индивидуальный маркетплейс",
            text: "<p>VERY запомнит&nbsp;то, что вы&nbsp;любите, подберёт новое, доставит бережно и&nbsp;вовремя, поможет заказать десятки нужных услуг.</p>",
            img: require("@i/html/smart/smart-m-3.jpg"),
          },
        ],
      },

      image: {
        title: "Биобезопасность",
        text: "<p>В&nbsp;лобби и&nbsp;других общественных пространствах дома включена система обеззараживания воздуха с&nbsp;удалённым смарт-контроллером. Путь от&nbsp;подъезда до&nbsp;квартиры, может быть полностью бесконтактным&nbsp;&mdash; умный дом VERY мгновенно узнаёт вас, сам открывает все двери и&nbsp;вызывает лифт до&nbsp;нужного вам этажа.</p>",
        img: require("@i/html/home/feats-6.jpg"),
      },
    };
  },
};
</script>

<style scoped>
.main {
  background: #f8f8f8;
}

.agehlribbqkvwxvb :deep(.title) {
  max-width: 550px;
}
</style>
